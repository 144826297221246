<template>
    <svg xmlns="http://www.w3.org/2000/svg" :width="size+'px'" viewBox="0 0 100 70.062">
        <path :fill="color" id="fill" data-name="Youtube icon " d="M-865.607,575.929H-940.74a12.447,12.447,0,0,1-12.431-12.436V518.309a12.447,12.447,0,0,1,12.431-12.442h75.133a12.453,12.453,0,0,1,12.436,12.442v45.184A12.447,12.447,0,0,1-865.607,575.929ZM-911.85,526.55c-.7,0-1.214.639-1.214,1.515v25.4a1.4,1.4,0,0,0,1.268,1.5,1.223,1.223,0,0,0,.607-.167l22.326-12.565a1.61,1.61,0,0,0,.892-1.38,1.264,1.264,0,0,0-.628-1.155l-22.595-12.968a1.3,1.3,0,0,0-.656-.188Z" transform="translate(953.171 -505.867)"/>
    </svg>
</template>
<script>
    export default {
        name: 'iconYoutube',
        props: {
            size: {
                type: Number,
                default: 20,
            },
            color: {
                type: String,
                default: 'var(--main-navy)'
            }
        }
    }
</script>
